.modalContainer {
  max-width: 604px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .modalContent {
    width: 100%;
    .formContainer {
      width: 100%;
    }
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
}

:global(.globalComponentModalContainer._y0yN4) {
  max-width: 100% !important;
}
