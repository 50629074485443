$greenColor: #4caf50;
$greyColor: #757575;

.modalRoot {
  backdrop-filter: blur(14px);
  display: flex;
  align-items: center;
  max-width: 100%;
  > div {
    margin: auto;
    > div {
      max-width: 100% !important;
    }
  }
  .modal {
    max-width: 1000px;
    width: 900px;
    // width: unset;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 960px) {
      width: 770px;
    }
    @media screen and (max-width: 833px) {
      width: 670px;
    }
  }
}

.companyMainFrame {
  padding: 0px 40px 24px 40px;
  overflow-y: auto;
  .wrap {
    height: 100%;
  }
  .filterRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    text-wrap: nowrap;
    align-items: center;
    margin-bottom: 16px;
    .searchFilterInput {
      width: 100%;
    }
  }
  .summary {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    .title {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      color: #212121;
    }
  }
  .container {
    margin: 0px 20px 20px 20px;
    padding: 24px 24px;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    .buttonBlock {
      padding-top: 16px;
      margin-left: auto;
    }
    .sourceBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .headSourceBlock {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .questionBlock {
          min-width: 200px;
          max-width: 430px;
          display: flex;
          word-wrap: break-word;
          gap: 10px;
        }
      }
      .questionTitleCurrent {
        width: fit-content;
        display: flex;
        flex-direction: row;
        gap: 8px;
        color: #4caf50;
        svg {
          width: 20px;
          height: 20px;
        }
        svg > path {
          fill: #4caf50;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .infoSourceBlock {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .qbuttonsvg {
        overflow: visible;
      }
      .answered {
        fill: $greenColor;
        color: $greenColor;
      }
      .required {
        fill: $greyColor;
        color: $greyColor;
      }
      .questionLink {
        padding: 0px;
        font-size: 16px;
        font-weight: 500;
        text-transform: none;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .componentsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .componentBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 20px;
        word-wrap: break-word;
        padding: 0px 30px;
        .componentNum {
          width: 100%;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .componentDetails {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 50px;
          div {
            font-weight: 500;
            span {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .companyMainFrame {
    .container {
      .sourceBlock {
        padding: 16px;
        flex-direction: column;
        row-gap: 16px;
        justify-content: space-between;
      }
      .buttonBlock {
        margin: 0 auto;
      }
      .componentsContainer {
        .componentBlock {
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }
}

.hlight {
  background-color: yellow;
}
