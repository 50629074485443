.customCo2Item {
  width: 100%;
  .itemsTitle {
    width: 100%;
    height: fit-content;
    margin-bottom: 24px;
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .inputsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .inputRow {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 14px;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .customCo2Input {
        width: 100%;
      }
      .costInput {
        width: 100%;
      }
      .checkboxContainer {
        min-width: fit-content;
        display: flex;
        align-items: center;
        gap: 8px;
        input[type='checkbox'] {
          width: 20px;
          height: 20px;
        }

        label {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

.listHeader {
  color: #212121 !important;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}

.selectPlaceholder {
  font-size: 16px !important;
  font-weight: 400px !important;
}
