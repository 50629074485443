.answersWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 0px;
  .answersInfoBlock {
    width: 100%;
    max-width: 1436px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
    .answersInfo {
      width: 850px;
      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        margin: 0px;
      }
      p {
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .answersfiltre {
      width: 100%;
      max-width: 536px;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: end;
      gap: 13px;

      .filtreSelect {
        width: 247px;
        height: 48px;
        border: green;
      }

      .searchBox {
        height: 48px;
        width: 100%;
        max-width: 276px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #efefef;
        .searchImg {
          margin: 14px;
          width: 17.5px;
          height: 17.5px;
          svg > path {
            fill: #555555;
          }
        }

        .search {
          background-color: #ffffff;
          height: 24px;
          width: 100%;
          font-size: 16px;
          .searchInput {
            border: #ffffff;
            background-color: transparent;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .answersActionBlock {
    width: 100%;
    max-width: 1436px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;

    .scopeList {
      width: 100%;
      max-width: 604px;
      height: 100%;
      max-height: 704px;
      overflow-y: scroll;
      padding: 0px 15px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .scopeBlock {
        background-color: white;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.11);
        width: 100%;
        height: fit-content;
        border-radius: 16px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .scopeTitle {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

          svg {
            width: 24px;
          }
        }
        .scopeContent {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .scopeItem {
            width: 100%;
            background-color: white;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
            backdrop-filter: blur(16px);
            border-radius: 12px;
            padding: 24px 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .scopeItemInfo {
              width: fit-content;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 12px;
              .verifiedYesIcon {
                width: 24px;
                height: 24px;
              }
              .verifiedNoIcon {
                width: 24px;
                height: 24px;
                border: 1.5px solid #c4c4c4;
                border-radius: 50%;
                path {
                  fill: #c4c4c4 !important;
                }
              }
              .helpIcon {
                width: 24px;
                height: 24px;
                path {
                  fill: #eb793f !important;
                }
              }
            }
            .questionButton {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              padding: 0px;
              gap: 5px;
              svg {
                width: 24px;
                height: 24px;
              }
              svg > path {
                fill: #18397a !important;
              }
            }
          }
        }
      }
    }

    .answersList {
      background-color: white;
      box-shadow: 0px 4px 16px 0px #0000001c;
      width: 100%;
      max-width: 812px;
      border-radius: 12px;
      padding: 20px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .answerItem {
        background-color: white;
        box-shadow: 0px 4px 16px 0px #0000000f;
        backdrop-filter: blur(16px);
        width: 100%;
        border-radius: 12px;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .answerItemAction {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .userData {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            .userInfo {
              width: fit-content;
              display: flex;
              flex-direction: column;
              p {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
              }

              p:last-child {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
          .answerAction {
            display: flex;
            flex-direction: row;
          }
        }
        .answercontent {
          width: 100%;
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  .applyBlock {
    width: 100%;
    height: fit-content;
    max-width: 1436px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
