.modalContainer {
  max-width: 604px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .inputRow {
        display: flex;
        flex-direction: row;
        gap: 12px;
        .inputField {
          width: 100%;
        }
        .phoneInput {
          height: 50px !important;
          width: 100% !important;
          background: #ffffff !important;
          border: 1px solid #efefef !important;
          border-radius: 12px !important;
          font-size: 16px;
        }
        .phone {
          height: 50px;
          width: 44px !important;
          background: transparent !important;
          border: none !important;
          border-radius: 12px !important;

          & > div {
            padding: 0 0 0 20px !important;
            position: initial !important;
            &:focus,
            &:hover {
              background-color: transparent !important;
            }

            &[aria-expanded='true'] {
              background: transparent !important;
            }
          }
        }
        :global(.react-tel-input .country-list) {
          background-color: white !important;
          border: 1px solid #efefef !important;
          border-radius: 10px !important;
          width: 260px !important;
          max-height: 200px !important;
          box-shadow: 0px 4px 14px 0px #0000000a !important;
          scrollbar-width: thin !important; // Pentru Firefox
          scrollbar-color: #ccc transparent !important;
        }
      }
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      .modal_buttonSend {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 48px;
        width: 130px;
        border: none;
        .sendIcon {
          width: 24px;
          height: 24px;
          line-height: normal !important;

          svg > path {
            stroke: white !important;
          }
        }
      }
    }
  }
}
:global(.globalSupplierModalContainer._y0yN4) {
  width: 100% !important;
  max-width: 100% !important;
}