$fillGreenColor: #2d8342;

.calculatorCostOuterBlock {
  display: flex;
  flex-wrap: unset;
  flex-direction: column;
  // max-width: 500px;

  @media screen and (max-width: 470px) {
    // padding: 12px 0 10px;
    margin-top: 0;
    min-width: 290px;
  }
  @media screen and (min-width: 1024px) {
    align-self: end;
    // max-width: 1328px;
    // width: 72vw;

    &.fit {
      width: fit-content;
    }
  }

  .calculatorCostInnerBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 30px;
    background-color: $fillGreenColor;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    padding-top: 10px;
    background: $fillGreenColor;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
    border-radius: 0px 0px 12px 12px;
    color: white;
    font-weight: 400;
    z-index: 1;
    @media screen and (max-width: 1024px) {
      width: 100% !important;
      justify-content: center;
    }
    @media screen and (max-width: 470px) {
      padding: 12px 0 10px;
      margin-top: 0;
    }
    @media screen and (min-width: 1024px) {
      align-self: end;
    }

    .calculatorCostText {
      margin-left: 5px;
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      gap: 5px;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
      }
    }

    .calculatorCostIco {
      display: inline-flex;
      .buttonHelp {
        padding: 0px 10px !important;
        min-width: 0 !important;
      }

      div {
        padding: 0 10px;
      }
      svg {
        fill: white;
      }
    }
  }
}
