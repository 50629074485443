$navyColor: #18397a;
$whiteColor: #fff;
$lightGrayColor: #efefef;
$greenColor: #4caf50;

.calculatorContainer {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-bottom: 32px;

  .breadcrumps {
    width: 100%;
    padding: 8px 24px;
    white-space: nowrap;
    overflow-x: auto;
    font-size: 12px;
    color: #757575;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

    a {
      border-bottom: 1px solid rgba(#757575, 0.5);

      &:hover {
        border-bottom-color: rgba($navyColor, 0.8);
      }
    }
  }

  .content {
    flex-direction: column;
    height: 100%;
    max-width: 500px;
    padding: 0 25px;

    .chartArea {
      height: calc(100vw - 50px);
      width: calc(100vw - 50px);
      max-width: 400px;
      max-height: 400px;
      margin: 40px auto 20px auto;
    }

    .infoArea {
      max-width: 400px;
      margin: 0 auto 28px auto;
    }
    .middleContainer {
      width: 72vw;
      max-width: 1328px;

      .infoWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .lastupdateInfo {
          display: flex;
          flex-direction: row;

          .checkIcon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            svg > path {
              fill: $greenColor !important;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            color: #757575;
          }
        }
      }
      .viewMobileButton {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .content {
      .middleContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .infoWrapper {
          width: 100%;
          max-width: 500px;
          .lastupdateInfo {
            display: none;
          }
          .paginationDescktop {
            display: none;
          }
          .calculationCostWrapper {
            width: 100%;
            max-width: 500px;
          }
        }
        .navigationButtons {
          width: 100%;
          max-width: 500px;
          column-gap: normal;
          justify-content: space-between;
          .viewDescktopButton {
            display: none;
          }
          .paginationMobile {
            display: block;
          }
        }
        .viewMobileButton {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    height: 100%;
    // padding-top: 40px;
    overflow-y: auto;

    .breadcrumps {
      position: absolute;
      z-index: 1;
      padding-left: 5vw;
      margin-top: -40px;
    }

    .content {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: unset;
      min-height: 500px;

      .chartArea {
        width: 50%;
        max-width: 250px;
        height: 48%;
        margin: auto auto auto calc(25% - 100px);
      }

      .infoArea {
        width: 50%;
        max-width: 37vw;
        height: 48%;
        min-height: 250px;
        margin: auto auto auto 0;

        & > div {
          margin-left: 10%;
        }
      }
    }
  }

  @media screen and (min-width: 1680px) {
    .content {
      // padding-top: 50px;

      .chartArea {
        max-width: 280px;
        max-height: 280px;
        margin-left: calc(25% - 140px);
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .breadcrumps {
      padding-left: 12vw;
    }
  }
}

.navigationButtons {
  display: flex;
  column-gap: 130px;
  row-gap: 16px;
  justify-content: end;
  margin-top: 24px;
  margin-bottom: 24px;
  .paginationMobile {
    display: none;
  }
  @media screen and (max-width: 666px) {
    padding: 0px 48px;
  }
  @media (min-width: 1350px) {
    .back {
      order: -1;
    }
  }
}
